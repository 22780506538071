import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import { TableCell, TableRow, Typography } from "../../../components/util";
import { PaginatedResults } from "../../../components/content";
import InvitationService, {
  InvitationResponse,
} from "../../../services/invitation/invitation.service";
import { formatDateTime } from "../../../helpers/dateTime";
import { InviteStatusChip } from "./InviteStatusChip";
import { InviteLink } from "./InviteLink";
import { AuthContext } from "../../../contexts";
import { IOrganisation } from "../../../services/admin/admin.service";

type Props = {
  /**
   * @param org_id_filter Filter invitations for given org_id
   */
  org_id_filter?: IOrganisation["id"];
};

export const InvitationsList: React.FC<Props> = ({ org_id_filter }) => {
  const { search } = useLocation();
  const userContext = useContext(AuthContext);

  const urlParams: Record<string, any> = useMemo(
    () => queryString.parse(search),
    [search],
  );

  const [searchTerm, setSearchTerm] = useState<string>(
    urlParams?.searchTerm ? urlParams?.searchTerm : "",
  );

  useEffect(() => {
    setSearchTerm(urlParams?.searchTerm);
  }, [urlParams?.searchTerm]);

  const invitationsRow = (invitationsSlice: InvitationResponse[]) => {
    return (
      <>
        {invitationsSlice.map((invitation: InvitationResponse) => (
          <TableRow key={invitation.id}>
            <TableCell expand={true}>
              <InviteLink
                invitation={invitation}
                active_user_roles={userContext.user?.roles || []}
              />
            </TableCell>
            <TableCell>
              <Typography styled={{ text: { case: "title" } }}>
                {invitation.policy_number}
              </Typography>
            </TableCell>
            <TableCell expand={true}>
              <Typography truncate={true}>{invitation.email}</Typography>
            </TableCell>
            <TableCell>
              <InviteStatusChip status={invitation.status} />
            </TableCell>
            <TableCell>{formatDateTime(invitation.created_at)}</TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  let service = org_id_filter
    ? InvitationService.getByOrgId
    : InvitationService.getAll;
  let searchService = org_id_filter
    ? InvitationService.searchByOrgId
    : InvitationService.search;

  return (
    <PaginatedResults
      id="invitations-all"
      searchTerm={searchTerm}
      searchService={searchService}
      service={service}
      serviceParams={org_id_filter ? { id: org_id_filter } : undefined}
      tableColumns={[
        { title: "Invitation", expand: true },
        { title: "Policy" },
        { title: "Invitee" },
        { title: "Status", collapse: true },
        { title: "Created", collapse: true },
      ]}
      tableRows={invitationsRow}
      missing={{
        icon: "checklist",
        type: "invitations",
        message: "This is where we'll show all invitations.",
      }}
    />
  );
};
