import { useQuery, UseQueryResult } from "react-query";
import organisationService from "../../../services/organisation/organisation.service";
import { IOrganisation } from "../../../services/admin/admin.service";
import { PaginatedApiResponse } from "../../../types/pagination.type";
import { hasAllowedRoles } from "../../../helpers";
import { useContext } from "react";
import { AuthContext } from "../../../contexts";
import { SelectOption } from "../../../components/form/SelectWithValidation/SelectWithValidation";
import { uuid } from "../../../types";

// This is a custom hook that fetches organisations and returns them as options
// Only for admin and super admmin users
export const useOrgSelectOptions = (options: {
  org_search_term?: string;
  policy_id?: uuid;
  filtered_org_ids?: uuid[];
}): UseQueryResult<SelectOption<IOrganisation>[]> => {
  const { user } = useContext(AuthContext);
  const is_privileged_user = hasAllowedRoles(user?.roles, {
    allowed: ["admin", "super_admin"],
  });

  const fetchOrgs = async (): Promise<
    SelectOption<IOrganisation>[] | undefined
  > => {
    let res: PaginatedApiResponse<IOrganisation>;
    if (is_privileged_user) {
      if (!options.org_search_term) {
        res = await organisationService.getAllOrganisations(0, 50, {
          policy_id: options.policy_id,
        });
      } else {
        res = await organisationService.getAllOrganisationsBySearchTerm(
          options.org_search_term,
          0,
          25,
          { policy_id: options.policy_id },
        );
      }
      return res?.hits
        ?.map(org => ({
          label: org.name,
          value: org.id,
          meta: org,
        }))
        .filter(org => !options.filtered_org_ids?.includes(org.value));
    } else {
      return user?.organisations
        ?.map(org => ({
          label: org.name,
          value: org.id,
          meta: org,
        }))
        .filter(org => !options.filtered_org_ids?.includes(org.value));
    }
  };

  return useQuery({
    queryKey: [
      "org_select_options",
      options?.org_search_term,
      options?.policy_id,
      user?.id,
      `filtered-${JSON.stringify(options.filtered_org_ids)}`,
    ],
    queryFn: fetchOrgs,
  });
};
