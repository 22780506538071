/**
 * Reusable validation rules
 */

import { ValidateResult } from "react-hook-form";
import { SelectOption } from "./SelectWithValidation/SelectWithValidation";

export const validation_rules = {
  /**
   * Validation patterns
   *
   * Commonly used patterns for validation
   * We can add and centralise here patterns from third party lirbaries.
   */
  patterns: {
    email: /^[^\s@]+@[^\s@]+[\w-]{2,4}$/,
  },

  /**
   * Validation rules for use with sc-web-ui select component
   *
   * @important These rules are for use with SelectWithValidation and SelectOption only.
   * @important For simple input fields, use react-hook-form built-in validation rules.
   **/
  select: {
    required:
      (message?: string) =>
      (option: SelectOption | SelectOption[] | undefined): ValidateResult => {
        if (option && Object.prototype.hasOwnProperty.call(option, "value")) {
          return (option as SelectOption).value
            ? true
            : message || "This field is required";
        } else if (
          option &&
          Object.prototype.hasOwnProperty.call(option, "length") &&
          ((option as SelectOption[]).length > 1 || // there is several options selected
            ((option as SelectOption[]).length > 0 && // only 1 object in the array could still be undefined
              !!(option as SelectOption[])[0].value))
        ) {
          return true;
        }
        return message || "This field is required";
      },
    pattern:
      (pattern: string | RegExp, message: string) =>
      (option: SelectOption | SelectOption[] | undefined): ValidateResult => {
        if (option) {
          let options: SelectOption[] = Object.prototype.hasOwnProperty.call(
            option,
            "value",
          )
            ? [option as SelectOption]
            : (option as SelectOption[]);
          for (let i = 0; i < options.length; i++) {
            if (options[i].value?.match(pattern) === null) {
              return message;
            }
          }
        }
        return true;
      },
    maxLength:
      (maxLength: number, message?: string) =>
      (option: SelectOption | SelectOption[] | undefined): ValidateResult => {
        if (option) {
          let options: SelectOption[] = Object.prototype.hasOwnProperty.call(
            option,
            "value",
          )
            ? [option as SelectOption]
            : (option as SelectOption[]);
          for (let i = 0; i < options.length; i++) {
            if (options[i].value.length > maxLength) {
              return message || `Maximum length: ${maxLength}`;
            }
          }
          return true;
        }
        return message || `Maximum length: ${maxLength}`;
      },
    minLength:
      (minLength: number, message?: string) =>
      (option: SelectOption | SelectOption[] | undefined): ValidateResult => {
        if (option) {
          let options: SelectOption[] = Object.prototype.hasOwnProperty.call(
            option,
            "value",
          )
            ? [option as SelectOption]
            : (option as SelectOption[]);
          for (let i = 0; i < options.length; i++) {
            if (options[i].value.length < minLength) {
              return message || `Minimum length: ${minLength}`;
            }
          }
          return true;
        }
        return message || `Minimum length: ${minLength}`;
      },
    minQty:
      (minQty: number, message?: string) =>
      (option: SelectOption | SelectOption[] | undefined): ValidateResult => {
        if (option) {
          let options: SelectOption[] = Object.prototype.hasOwnProperty.call(
            option,
            "value",
          )
            ? [option as SelectOption]
            : (option as SelectOption[]);
          return options.length > minQty
            ? message || `Select at least ${minQty} options`
            : true;
        }
        return message || `Select at least ${minQty} options`;
      },
    maxQty:
      (maxQty: number, message?: string) =>
      (option: SelectOption | SelectOption[] | undefined): ValidateResult => {
        if (option) {
          let options: SelectOption[] = Object.prototype.hasOwnProperty.call(
            option,
            "value",
          )
            ? [option as SelectOption]
            : (option as SelectOption[]);
          return options.length > maxQty
            ? message || `${maxQty} options maximum`
            : true;
        }
        return message || `${maxQty} options maximum`;
      },
  },
};

export type Select_Validation_Rule = keyof typeof validation_rules.select;
