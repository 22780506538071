import * as React from "react";
import { UseQueryResult } from "react-query";
import { IClaim } from "../../../types";

type Props = { claim_query: UseQueryResult<IClaim | undefined> };

export const TabSettings: React.FC<Props> = ({ claim_query }) => {
  console.log("claim_query data:", claim_query.data);

  return null;
};
