import { useHistory, useParams } from "react-router-dom";
import AutomationService from "../../services/automation/automation.service";
import { Grid } from "../../components/util";
import { Header, Section } from "../../components/layout";
import { AutomationCreatePayload } from "../../types/automations.type";
import { AutomationForm, AutomationFormFields } from "./AutomationForm";
import { useAutomationQuery } from "../../hooks/queries/useAutomationQuery";
import { SelectOption } from "../../components/form/SelectWithValidation";
import { Industry } from "../../services/industries/industries.service";
import { Toolkit } from "../../services/toolkits/toolkits.service";
import { useMemo } from "react";
import { Spinner } from "@safetyculture/sc-web-ui";
import { AutomationInfoBanner } from "./AutomationInfoBanner";

export const AutomationEditPage = () => {
  const history = useHistory();
  const params = useParams<{ automation_id: string }>();
  const automation = useAutomationQuery(params.automation_id);

  const default_industries: SelectOption<Industry>[] = useMemo(() => {
    return (
      automation.data?.industries?.map(i => ({
        value: i.id,
        label: i.occupation,
        meta: i,
        description: `${i.anzsic} ${i.name}`,
      })) || []
    );
  }, [automation.data?.industries]);

  const default_toolkits: SelectOption<Toolkit>[] = useMemo(() => {
    return (
      automation.data?.toolkits
        ?.map(t => ({
          value: t.id,
          label: t.code || "",
          meta: t,
          description: `${t.name})`,
        }))
        .filter(t => !!t.label) || []
    );
  }, [automation.data?.toolkits]);

  const handleSubmit = async (data: AutomationFormFields): Promise<boolean> => {
    if (params.automation_id) {
      const automation_payload: AutomationCreatePayload = {
        name: data.name,
        industry_ids: data.industries.map(i => i.value),
        toolkit_ids: data.toolkits.map(t => t.value),
        enabled: data.enabled || false,
      };

      const updated = await AutomationService.update({
        ...automation_payload,
        automation_id: params.automation_id,
      });

      if (updated) {
        automation.refetch();
        history.replace(`/automations/${params.automation_id}`);
        return true;
      }
    }
    return false;
  };

  const handleCancel = () => {
    history.push(`/automations/${params.automation_id}`);
  };

  return (
    <Grid>
      <Header
        title="Edit Automation"
        titleIcon="dependabot"
        titleIconPosition="end"
        type="article"
        withLink={{
          path: `/automations/${params.automation_id}`,
          text: "Automation details page",
        }}
      >
        <AutomationInfoBanner />
      </Header>

      {automation.isLoading ? (
        <Spinner />
      ) : (
        <Section>
          <AutomationForm
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            type="Edit"
            name={automation.data?.name}
            industries={default_industries}
            toolkits={default_toolkits}
            enabled={automation.data?.enabled}
          />
        </Section>
      )}
    </Grid>
  );
};
