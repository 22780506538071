import { useQuery, UseQueryResult } from "react-query";
import IndustriesService, {
  Industry,
} from "../../../services/industries/industries.service";
import { PaginatedApiResponse } from "../../../types/pagination.type";
import { SelectOption } from "../../../components/form/SelectWithValidation/SelectWithValidation";

export const useIndustrySelectOptions = (options?: {
  industry_search_term?: string;
}): UseQueryResult<SelectOption<Industry>[]> => {
  const fetchIndustries = async (): Promise<
    SelectOption<Industry>[] | undefined
  > => {
    let industries: Industry[] | undefined;

    if (options?.industry_search_term) {
      const paginated_results: PaginatedApiResponse<Industry> =
        await IndustriesService.searchAll(options.industry_search_term);
      industries = paginated_results.hits;
    } else {
      industries = await IndustriesService.getAllIndustries();
    }
    if (industries) {
      const result = industries
        .map(industry => ({
          label: industry.occupation || "",
          value: industry.id || "",
          meta: industry,
          description: `${industry.anzsic || ""} ${industry.name || ""}`,
        }))
        .filter(option => option.label && option.value);

      return result;
    }
    return [];
  };

  return useQuery({
    queryKey: ["industries_select_options", options?.industry_search_term],
    queryFn: fetchIndustries,
  });
};
