import { useState } from "react";
import { Header, Section } from "../../../../components/layout";
import { PaginatedResults } from "../../../../components/content";
import {
  Button,
  CircularProgress,
  TableCell,
  TableRow,
  Link,
  Popover,
} from "../../../../components/util";

import toolkitService, {
  Toolkit,
} from "../../../../services/toolkits/toolkits.service";
import * as Styled from "../styles.css";
import { AddToolkitToOrgModal } from "./AddToolkitToOrgModal";
import { useToolkitQuery } from "../../../../hooks/queries/useToolkitQuery";

export const ToolkitsTab = ({ orgId }: { orgId: string }) => {
  const toolkits_query = useToolkitQuery({ org_id: orgId });

  const [is_add_org_modal_open, setAddOrgModal] = useState(false);
  const openModal = () => setAddOrgModal(true);
  const closeModal = () => setAddOrgModal(false);

  const ToolkitRow = (toolkits: Toolkit[]) => (
    <>
      {toolkits.map(toolkit => (
        <TableRow key={toolkit.id}>
          <TableCell>
            <Link href={`/toolkits/${toolkit.id}`}>{toolkit.name}</Link>
          </TableCell>
          <TableCell>{toolkit.code}</TableCell>
          <TableCell>
            <OverflowMenuButton id={toolkit.id} />
          </TableCell>
        </TableRow>
      ))}
    </>
  );

  const OverflowMenuButton = ({ id }: { id: Toolkit["id"] }) => (
    <Popover
      alignment="end"
      trigger={
        <Styled.MoreOptionIconButton icon="kebab-horizontal">
          Pop
        </Styled.MoreOptionIconButton>
      }
    >
      <Styled.MoreOptionSelectMenu>
        <Styled.MoreOptionSelectOption>
          <Styled.LinkButton onClick={event => removeToolkit(event, id)}>
            Remove Toolkit
          </Styled.LinkButton>
        </Styled.MoreOptionSelectOption>
      </Styled.MoreOptionSelectMenu>
    </Popover>
  );

  const removeToolkit = async (event, toolkitId) => {
    event.preventDefault();
    await toolkitService.removeToolkitFromOrg(orgId, toolkitId);
    toolkits_query.refetch();
  };

  const handleToolkitAdded = async () => {
    closeModal();
    await toolkits_query.refetch();
  };

  return (
    <>
      <Section>
        <Header
          title="Toolkits"
          titleIcon="tools"
          titleIconPosition="start"
          type="section"
          actions={
            <Button onClick={openModal}>
              Add a toolkit
              <Styled.PlusIcon icon="plus" size="sm" />
            </Button>
          }
        />

        {toolkits_query.isLoading ? (
          <CircularProgress contained />
        ) : (
          <PaginatedResults
            id={`toolkits-org_id-${orgId}`}
            inputResults={{
              hits: toolkits_query.data ?? [],
              total: toolkits_query.data?.length ?? 0,
            }}
            tableColumns={[
              { title: "Name", expand: true },
              { title: "Code", expand: true },
              { title: "", collapse: true },
            ]}
            tableRows={ToolkitRow}
            missing={{
              icon: "pencil",
              message: "No toolkits associated to organisation",
              type: "toolkits",
            }}
          />
        )}
      </Section>

      {is_add_org_modal_open && (
        <AddToolkitToOrgModal
          orgId={orgId}
          onCancel={closeModal}
          onToolkitAdded={handleToolkitAdded}
        />
      )}
    </>
  );
};
