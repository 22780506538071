import { useEffect, useState } from "react";
import * as Styled from "./styles.css";
import toastEvent, { TOAST_SUCCESS, TOAST_FAILURE } from "./toast.event";

export const Toast = () => {
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    toastEvent.subscribe(TOAST_SUCCESS, event => {
      const message = event.detail.message;
      setSuccess(true);
      setError(false);
      setMessage(message);
    });

    toastEvent.subscribe(TOAST_FAILURE, event => {
      const message = event.detail.message;
      setError(true);
      setSuccess(false);
      setMessage(message);
    });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSuccess(false);
      setError(false);
      setMessage("");
    }, 5000); // 5 seconds

    return () => {
      clearTimeout(timer);
    };
  }, [message, success, error]);

  return (
    <>
      {message.length > 0 && (
        <Styled.ToastContainer $success={success}>
          {success && <Styled.SuccessIcon />}
          {error && <Styled.ErrorIcon />}
          <Styled.Message>{message}</Styled.Message>
        </Styled.ToastContainer>
      )}
    </>
  );
};

export default Toast;
