import styled from "styled-components";
import { theme } from "../../../styles";

import { Stack, TabPanel } from "@safetyculture/sc-web-ui";
import * as React from "react";
import { TabOverview } from "./TabOverview";
import { UseQueryResult } from "react-query";
import { IClaim } from "../../../types";
import { TabSettings } from "./TabSettings";
import { Cog } from "@safetyculture/icons-react";
import { unleashFeatureFlags } from "../../../constants";
import { useFlag } from "@unleash/proxy-client-react";

type Props = { claim_query: UseQueryResult<IClaim | undefined> };

export const ClaimDetailsTabs: React.FC<Props> = ({ claim_query }) => {
  const [active_tab, setActiveTab] = React.useState("/details");
  const claims_create_inspection_flag = useFlag(
    unleashFeatureFlags.createClaimInspection,
  );

  return (
    <StyledTabPanel
      active={active_tab}
      onTabChange={setActiveTab}
      variant="border"
    >
      <TabPanel.Panel label="Details" name="/details">
        <TabOverview claim_query={claim_query} />
      </TabPanel.Panel>
      {claims_create_inspection_flag && (
        <TabPanel.Panel
          label={
            <Stack align="center" direction="row" spacing="s1">
              <Cog size={16} />
              Settings
            </Stack>
          }
          name="/settings"
        >
          <TabSettings claim_query={claim_query} />
        </TabPanel.Panel>
      )}
    </StyledTabPanel>
  );
};

const StyledTabPanel = styled(TabPanel)`
  border-bottom: 1px solid ${theme.color.surface.border.weak.default};
  padding-bottom: 0;
  justify-content: space-between;
`;
