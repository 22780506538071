import { useContext } from "react";
import { InviteGrid } from "../styles.css";
import { Header, Section } from "../../../components/layout";
import { BrokerInviteForm } from "./BrokerInviteForm";
import InvitationService, {
  Invitation,
} from "../../../services/invitation/invitation.service";
import { useHistory } from "react-router-dom";
import { BrokerInvitationFields } from "./BrokerInvitationFields.models";
import { Spacing } from "../../../components/layout/Spacing";
import { AuthContext } from "../../../contexts";

type Props = {};

export const InviteBrokerToCare: React.FC<Props> = () => {
  const history = useHistory();
  const user_context = useContext(AuthContext);

  const handleSubmit = async (fields: BrokerInvitationFields) => {
    const invite: Pick<
      Invitation,
      | "firstname"
      | "lastname"
      | "email"
      | "role"
      | "sender_org_id"
      | "sender_first_name"
      | "sender_last_name"
    > = {
      email: fields.email,
      firstname: fields.firstname,
      lastname: fields.lastname,
      sender_first_name: user_context.user?.firstname,
      sender_last_name: user_context.user?.lastname,
      role: "broker", // Always "broker" for a broker invite
      sender_org_id: undefined, // expected null or undefined for broker invite
    };

    const created = await InvitationService.createInvitation(invite);
    if (created) {
      history.replace("/invitations"); // disable navigating back to form via browser back button
    }
  };

  return (
    <InviteGrid>
      <Header
        title={"Invite broker"}
        titleIcon="mail"
        titleIconPosition="end"
        type="article"
        withLink={{ path: "/users", text: "All users" }}
      />
      <Section>
        <Spacing $height={32} />
        <BrokerInviteForm onSubmit={handleSubmit} />
        <Spacing $height={32} />
      </Section>
    </InviteGrid>
  );
};
