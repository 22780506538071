import styled from "styled-components";
import { theme } from "./../../../styles";
import {
  Button,
  CircularProgress,
  FormRow,
  Icon,
  Link,
  Typography,
} from "./../../../components/util";

export const LogoutLink = styled(Link).attrs((props: any) => ({}))`
  text-decoration: none;
`;

export const BodyTypo = styled(Typography).attrs((props: any) => ({}))`
  margin-top: -20px;
  font-size: 20px;
  font-weight: 400;
  color: ${theme.color.neutral.text.weak.default};
`;

export const ContinueButton = styled(Button).attrs((props: any) => ({}))`
  margin-top: 40px;
  margin-bottom: 32px;
`;

export const EmailTypography = styled(Typography).attrs((props: any) => ({}))`
  margin-top: 10px;
  font-size: 17px;
  font-weight: 500;
  & a {
    color: ${theme.color.surface.text.weak.default};
  }
`;

export const FooterTypography = styled(Typography).attrs((props: any) => ({}))`
  & a {
    color: ${theme.color.surface.text.weak.default};
  }
`;

export const MarketingCheckbox = styled(FormRow).attrs((props: any) => ({}))`
  display: flex;
`;

export const MarketingCheckboxLabel = styled(Typography).attrs(
  (props: any) => ({}),
)`
  font-size: 14px;
  padding-top: 3px;
`;

export const CenterIllustrationContainer = styled.div`
  margin: 10px auto;
  width: 150px;
  height: 150px;
`;
