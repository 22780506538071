import axios from "../../axios";
import toastEvent from "../../components/util/toast/toast.event";
import { PaginatedApiResponse } from "../../types/pagination.type";

const toolkitService = {
  async create(toolkit) {
    try {
      const { data } = await axios.post("/toolkits", toolkit);

      toastEvent.success("Toolkit successfully added");

      return data.id;
    } catch (err: any) {
      const { data, status } = err.response;

      if (status === 409) {
        // TODO: should be RJSF validation error, not toast
        toastEvent.error(`${data.error.title}: ${data.error.detail}`);
      } else {
        throw err;
      }
    }
  },

  async update(id, toolId, toolkit) {
    try {
      await axios.put(`/toolkits/${id}`, { toolId, ...toolkit });

      toastEvent.success("Toolkit successfully updated");
    } catch (err: any) {
      const { data, status } = err.response;

      if (status === 409) {
        // TODO: should be RJSF validation error, not toast
        toastEvent.error(`${data.error.title}: ${data.error.detail}`);
      } else {
        throw err;
      }
    }
  },

  async getAll(
    search?: string,
    offset?: number,
    limit?: number,
  ): Promise<PaginatedApiResponse<Toolkit>> {
    const res = await axios.get("/toolkits/all", {
      params: {
        search,
        offset,
        limit,
      },
    });

    return res.data;
  },

  async getById(id: string): Promise<Toolkit> {
    const res = await axios.get(`/toolkits/toolkit/${id}`);

    const toolkit = res.data as Toolkit;

    return toolkit;
  },

  async getByCode(code: string): Promise<Toolkit> {
    const res = await axios.get(`/toolkits/code/${code}`);
    const toolkit = res.data as Toolkit;

    return toolkit;
  },

  async getToolkits(options: {
    org_id?: string;
    policy_id?: string;
  }): Promise<Toolkit[]> {
    try {
      const res = await axios.get(
        `/toolkits?org_id=${options.org_id || ""}&policy_id=${
          options.policy_id || ""
        }`,
      );

      return res.data as Toolkit[];
    } catch (err: any) {
      if (err.response?.status === 404) {
        // 404 on this endpoint means no toolkits found for this org
        return [] as Toolkit[];
      } else {
        throw err;
      }
    }
  },

  async addToolkitToOrg(org_id: string, toolkit_id: string) {
    try {
      await axios.post(`/toolkits/org/${org_id}/${toolkit_id}`);
      toastEvent.success("Toolkit added to organisation");
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.error) {
        toastEvent.error(
          `Failed to add toolkit to organisation. ${error.response.data.error}`,
        );
        throw new Error(error.response.data.error);
      }

      throw error;
    }
  },

  async removeToolkitFromOrg(orgId: string, toolkitId: string): Promise<void> {
    try {
      await axios.delete(`/toolkits/org/${orgId}/${toolkitId}`);
      toastEvent.success("Toolkit removed from organisation");
    } catch (err: any) {
      if (err.response?.data?.error) {
        toastEvent.error(`An error occurred: ${err.response.data.error}`);
        throw new Error(err.response.data.error);
      }

      throw err;
    }
  },
};

export interface Toolkit {
  id: string;
  name: string;
  code?: string;
  tools: Tool[];
  created_at: Date;
  updated_at: Date;
  deleted_at?: Date;
}

export interface Tool {
  id: string;
  name: string;
  toolkit_id: string;
  data: any;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

export default toolkitService;
