import { Header, Section } from "../../components/layout";
import UsersList from "./users";
import * as Styled from "./styles.css";
import { Button } from "@safetyculture/sc-web-ui";
import { useHistory } from "react-router-dom";

const Users = () => {
  const history = useHistory();

  const handleInviteBrokerRedirect = () => {
    history.push("/invitations/broker/invite");
  };

  return (
    <Styled.Grid $fullHeight={true}>
      <Header
        actions={
          <Button
            type="button"
            variant="primary"
            onClick={handleInviteBrokerRedirect}
          >
            Invite Broker
          </Button>
        }
        title="Users"
        titleIcon="people"
        type="article"
      />

      <Section>
        <UsersList />
      </Section>
    </Styled.Grid>
  );
};

export default Users;
