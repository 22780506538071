import { useHistory } from "react-router-dom";
import AutomationService from "../../services/automation/automation.service";
import { Grid } from "../../components/util";
import { Header, Section } from "../../components/layout";
import { AutomationCreatePayload } from "../../types/automations.type";
import { AutomationForm, AutomationFormFields } from "./AutomationForm";
import { AutomationInfoBanner } from "./AutomationInfoBanner";

export const AutomationCreatePage = () => {
  const history = useHistory();

  const handleSubmit = async (data: AutomationFormFields): Promise<boolean> => {
    const automation: AutomationCreatePayload = {
      name: data.name,
      industry_ids: data.industries.map(i => i.value),
      toolkit_ids: data.toolkits.map(t => t.value),
      enabled: data.enabled || false,
    };

    const created = await AutomationService.create(automation);

    if (created) {
      history.replace(`/automations/${created.created_id}`);
      return true;
    }
    return false;
  };

  return (
    <Grid>
      <Header
        title="Create New Automation"
        titleIcon="dependabot"
        titleIconPosition="end"
        type="article"
        withLink={{ path: "/automations", text: "All Automations" }}
      >
        <AutomationInfoBanner />
      </Header>

      <Section>
        <AutomationForm onSubmit={handleSubmit} type="Create" />
      </Section>
    </Grid>
  );
};
