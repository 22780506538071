import * as React from "react";
import * as Styled from "./styles.css";
import {
  capitiliseFirsrtChar,
  commaSeparated,
  formatDateTime,
  hasAllowedRoles,
} from "../../../helpers";
import {
  Link,
  TableRow,
  TableCell,
  TableRowSubhead,
  Typography,
} from "../../../components/util";
import { Header } from "../../../components/layout";
import { IClaim, IClaimContact } from "../../../types";
import claimService from "../../../services/claim/claim.service";
import { UseQueryResult } from "react-query";
import { InlineBanner, Stack } from "@safetyculture/sc-web-ui";
import { CircleInfo } from "@safetyculture/icons-react";
import { AuthContext } from "../../../contexts";

type Props = { claim_query: UseQueryResult<IClaim | undefined> };

export const TabOverview: React.FC<Props> = ({ claim_query }) => {
  const user_context = React.useContext(AuthContext);
  const contact_data: IClaimContact = React.useMemo(() => {
    const claimFormData = claim_query.data?.versions[0].claim_data;
    let contact_data: IClaimContact = {
      contact_name: "",
      phone_number: "",
      email: "",
    };

    if (claimFormData?.create) {
      contact_data = claimService.extractContactData(claimFormData);
    }
    return contact_data;
  }, [claim_query.data]);

  return (
    <Stack direction="column" spacing="s6">
      {/* Overview */}
      <Stack direction="column" spacing="s6">
        {hasAllowedRoles(user_context.user?.roles, { allowed: ["broker"] }) && (
          <InlineBanner icon={<CircleInfo />} status="information" title="">
            <Stack direction="column" spacing="s2">
              <Typography>
                Your claim is in review. It usually takes 24 hours and we'll get
                back to you via email.
              </Typography>
              <Typography>
                Got a question? Please contact us at{" "}
                <Link
                  href={`mailto:${process.env.REACT_APP_CARE_CLAIMS_EMAIL}`}
                >
                  {process.env.REACT_APP_CARE_CLAIMS_EMAIL}
                </Link>
              </Typography>
            </Stack>
          </InlineBanner>
        )}

        <Styled.Table
          columns={[
            { title: "", expand: true },
            { title: "", expand: true },
          ]}
          orientation="horizontal"
        >
          <TableRowSubhead>Overview</TableRowSubhead>
          {/* <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>
              <Chip>Review</Chip> // hardcoded as it is now, showing status removes value from the app. Let's put this back once it is dynamic
            </TableCell>
          </TableRow> */}
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>
              {
                claim_query.data?.versions[0].claim_data.create?.issue
                  ?.claim_type
              }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Incident date</TableCell>
            <TableCell>
              {formatDateTime(
                claim_query.data?.versions[0].claim_data.details?.incident_date,
                "verbose",
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Created</TableCell>
            <TableCell>
              {formatDateTime(claim_query.data?.created_at, "verbose")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Last updated</TableCell>
            <TableCell>
              {formatDateTime(claim_query.data?.updated_at, "verbose")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell textOverflow>Summary</TableCell>
            <TableCell textOverflow>
              {claim_query.data?.versions[0].claim_data.create?.issue?.summary}
            </TableCell>
          </TableRow>
          <TableRowSubhead>Details</TableRowSubhead>
          <TableRow>
            <TableCell>Estimated loss</TableCell>
            <TableCell>
              ${" "}
              {
                claim_query.data?.versions[0].claim_data.details?.what
                  ?.estimated_loss
              }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell textOverflow>Officials Involved</TableCell>
            <TableCell textOverflow>
              {commaSeparated(
                claim_query.data?.versions[0].claim_data.details
                  ?.officials_involved || [""],
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell textOverflow>Special instructions</TableCell>
            <TableCell textOverflow>
              {
                claim_query.data?.versions[0].claim_data.details
                  ?.special_instructions?.anything_else
              }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell textOverflow>Affected items</TableCell>
            <TableCell textOverflow>
              {
                claim_query.data?.versions[0].claim_data.details?.what
                  ?.affected_items
              }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell textOverflow>Affected persons</TableCell>
            <TableCell textOverflow>
              {claim_query.data?.versions[0].claim_data.details?.who?.how}
            </TableCell>
          </TableRow>

          <TableRowSubhead>Crinminal History</TableRowSubhead>
          <TableRow>
            <TableCell textOverflow>
              Has your client been charged with, or convicted of, any criminal
              offence in the last 10 years?
            </TableCell>
            <TableCell>
              {
                claim_query.data?.versions[0].claim_data.declarations
                  ?.charges_and_history?.criminal_offences
              }
            </TableCell>
          </TableRow>
          <TableRowSubhead>Insurance History</TableRowSubhead>
          <TableRow>
            <TableCell textOverflow>
              Has any insurer refused or cancelled cover or required special
              terms to insure your client?
            </TableCell>
            <TableCell>
              {
                claim_query.data?.versions[0].claim_data.declarations
                  ?.charges_and_history?.refused_cancelled
              }
            </TableCell>
          </TableRow>
        </Styled.Table>
      </Stack>

      {/* Policy section */}
      <Stack direction="column">
        <Header title="Policy" type="section" />
        <Styled.Table
          columns={[
            { title: "", expand: true },
            { title: "", expand: true },
          ]}
          orientation="horizontal"
        >
          <TableRowSubhead>Policy details</TableRowSubhead>
          <TableRow>
            <TableCell textOverflow>Insured name</TableCell>
            <TableCell textOverflow>
              {
                claim_query.data?.versions[0].claim_data.create.claim
                  .insured_name
              }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell textOverflow>Policy number</TableCell>
            <TableCell textOverflow>
              <Link href={`/policies/${claim_query.data?.policy_id}`} router>
                {
                  claim_query.data?.versions[0].claim_data.create.claim
                    .policy_number
                }
              </Link>
            </TableCell>
          </TableRow>
        </Styled.Table>
      </Stack>

      {/* Contact section */}
      <Stack direction="column">
        <Header title="Contact" type="section" />
        <Styled.Table
          columns={[
            { title: "", expand: true },
            { title: "", expand: true },
          ]}
          orientation="horizontal"
        >
          <TableRow>
            <TableCell textOverflow>Contact name</TableCell>
            <TableCell textOverflow>{contact_data.contact_name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell textOverflow>Contact email</TableCell>
            <TableCell textOverflow>{contact_data.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Contact number</TableCell>
            <TableCell>{contact_data.phone_number}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Contact method (preferred)</TableCell>
            <TableCell>
              {capitiliseFirsrtChar(
                claim_query.data?.versions[0].claim_data.create?.claim
                  ?.preferred_contact_method,
              )}
            </TableCell>
          </TableRow>
        </Styled.Table>
      </Stack>

      {/* Declarations section */}
      <Stack direction="column">
        <Header title="Declarations" type="section" />
        <Styled.Table
          columns={[
            { title: "", expand: true },
            { title: "", expand: true },
          ]}
          orientation="horizontal"
        >
          <TableRow>
            <TableCell textOverflow>
              I acknowledge that I have read and agree to the privacy consent
              and authorisation.
            </TableCell>
            <TableCell textOverflow>
              {claim_query.data?.versions[0].claim_data.declarations
                ?.declarations?.privacy_consent
                ? "Yes"
                : "No"}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell textOverflow>
              I declare all the above details are true in every respect to the
              best of my knowledge.
            </TableCell>
            <TableCell textOverflow>
              {claim_query.data?.versions[0].claim_data.declarations
                ?.declarations.truth
                ? "Yes"
                : "No"}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell textOverflow>
              I acknowledge that a claim may be refused and/or the policy may be
              cancelled if that answers or information provided is untrue,
              inaccurate, or concealed.
            </TableCell>
            <TableCell>
              {claim_query.data?.versions[0].claim_data.declarations
                ?.declarations?.refusability
                ? "Yes"
                : "No"}
            </TableCell>
          </TableRow>
        </Styled.Table>
      </Stack>
    </Stack>
  );
};
