import { useHistory } from "react-router-dom";
import { Header, Section } from "../../components/layout";

import OrganisationsList from "./OrganisationsList";
import * as Styled from "./styles.css";
import { Button } from "@safetyculture/sc-web-ui";

const Organisations = () => {
  const history = useHistory();
  const buttonAction = (): any => {
    history.push({
      pathname: `/organisation/create`,
    });
  };
  return (
    <Styled.Grid $fullHeight={true}>
      <Header
        title="Organisations"
        titleIcon="organization"
        type="article"
        actions={<Button onClick={buttonAction}>Create Organisation</Button>}
      />
      <Section>
        <OrganisationsList />
      </Section>
    </Styled.Grid>
  );
};

export default Organisations;
