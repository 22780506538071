import * as React from "react";
import { TPolicyStatus } from "../../types/policy.type";
import {
  BadgeSize,
  BadgeVariant,
} from "@safetyculture/sc-web-ui/cjs/packages/react/badge/types";
import { Badge } from "@safetyculture/sc-web-ui";

type Props = { status: TPolicyStatus; size?: BadgeSize };

export const PolicyStatusBadge: React.FC<Props> = ({
  status = "unknown",
  size = "medium",
}) => {
  return (
    <Badge size={size} variant={variant[status]}>
      {text[status]}
    </Badge>
  );
};

const text: { [key in TPolicyStatus]: string } = {
  issued: "Issued",
  active: "Active",
  cancelled: "Cancelled",
  expired: "Expired",
  unknown: "Unknown",
  prerenewal: "Prerenewal",
};

const variant: { [key in TPolicyStatus]: BadgeVariant } = {
  issued: "information",
  active: "positive",
  cancelled: "default",
  expired: "default",
  unknown: "negative",
  prerenewal: "warning",
};
