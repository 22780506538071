import axios from "../../axios";
import { Invitation } from "../invitation/invitation.service";
import { formatFactors, formatScores } from "../scores/scores.service";
import safetycultureService from "../safetyculture/safetyculture.service";
import { IOrganisation } from "../admin/admin.service";
import {
  OrderType,
  PaginatedApiResponse,
  SortType,
} from "../../types/pagination.type";
import toastEvent from "../../components/util/toast/toast.event";

export enum PolicyStatus {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  VOIDED = "VOIDED",
  CANCELLED = "CANCELLED",
  DRAFT = "DRAFT",
}

export interface EvariPolicy {
  policy_id: string;
  policy_product_id: string;
  policy_number: string;
  policy_created_date: string;
  policy_expiry_date: string;
  policy_updated_date: string;
  policy_inception_date: string;
  policy_status: PolicyStatus;
  agent_id: string;
  agent_email: string;
  agent_name: string;
  agent_role: string;
  team_id: string;
  customer_id: string;
  customer_insured_name: string;
  customer_first_name: string;
  customer_last_name: string;
  customer_email: string;
  customer_organisation_id?: string;
  anzsic_code: string;
  invitations?: Invitation[];
  is_registered?: boolean;
  product_type: string;
}

const organisationService = {
  async create(orgData: any): Promise<IOrganisation> {
    try {
      const { data } = await axios.post(`/organisations/create`, {
        ...orgData,
      });
      toastEvent.success(`Organisation Created`);

      return data as IOrganisation;
    } catch (err) {
      toastEvent.error(`Something went wrong, try again`);
      throw err;
    }
  },

  async getPortfolioClientList(
    organisation_id: string,
  ): Promise<EvariPolicy[]> {
    const { data } = await axios.get(
      `/organisations/${organisation_id}/portfolio-client-list`,
    );

    return data as EvariPolicy[];
  },

  async getAllOrganisations(
    offset: number,
    limit: number,
    dataParams: {
      types?: string[] | null;
      policy_id?: string;
    },
  ): Promise<PaginatedApiResponse<IOrganisation>> {
    const order_by: OrderType = "desc";
    const sort_type: SortType = "created_at";

    const types = dataParams?.types;
    const policy_id = dataParams?.policy_id;

    let params = new URLSearchParams({
      order_by,
      sort_type,
      limit: `${limit}`,
      page_number: `${offset}`,
    });

    if (types && Array.isArray(types)) {
      for (let t of types) {
        params.append("types[]", t);
      }
    }

    const API = policy_id
      ? `/organisations/policy/${policy_id}`
      : `/organisations/all`;

    const Org = await axios.get(API, { params });

    return Org.data as PaginatedApiResponse<IOrganisation>;
  },

  async getAllOrganisationsBySearchTerm(
    searchTerm: string,
    offset: number,
    limit: number,
    dataParams: {
      filter?: string[];
      policy_id?: string;
    },
  ): Promise<PaginatedApiResponse<IOrganisation>> {
    const order_by: OrderType = "desc";
    const sort_type: SortType = "created_at";

    const filter = dataParams?.filter;
    const policy_id = dataParams?.policy_id;

    let params = new URLSearchParams({
      searchTerm: searchTerm.trim(),
      order_by,
      sort_type,
      limit: `${limit}`,
      page_number: `${offset}`,
    });

    if (filter) {
      filter.forEach(f => {
        params.append("filter", f);
      });
    }
    const API = policy_id
      ? `/organisations/search/policy/${policy_id}`
      : `/organisations/all`;

    const Org = await axios.get(API, {
      params,
    });

    return Org.data as PaginatedApiResponse<IOrganisation>;
  },

  async getAllParents(
    offset: number,
    limit: number,
    types: string[] | null | undefined,
  ): Promise<PaginatedApiResponse<IOrganisation>> {
    const order_by: OrderType = "desc";
    const sort_type: SortType = "created_at";
    const id = window.location.pathname.replace("/organisations/", "");

    let params = new URLSearchParams({
      id,
      order_by,
      sort_type,
      limit: `${limit}`,
      page_number: `${offset}`,
    });

    const Org = await axios.get("/organisations/associated/parent", { params });

    return Org.data as PaginatedApiResponse<IOrganisation>;
  },

  async getAllChild(
    offset: number,
    limit: number,
    types: string[] | null | undefined,
  ): Promise<PaginatedApiResponse<IOrganisation>> {
    const order_by: OrderType = "desc";
    const sort_type: SortType = "created_at";
    const id = window.location.pathname.replace("/organisations/", "");

    let params = new URLSearchParams({
      id,
      order_by,
      sort_type,
      limit: `${limit}`,
      page_number: `${offset}`,
    });

    const Org = await axios.get("/organisations/associated/child", { params });

    return Org.data as PaginatedApiResponse<IOrganisation>;
  },

  async getPortfolioClientUnmapped(
    policy_number: string,
  ): Promise<EvariPolicy> {
    const { data } = await axios.get(`/policy/client/${policy_number}/detail`);
    return data as EvariPolicy;
  },

  async getPortfolioClient(
    type: "cpx" | "bpk" | "wc",
    policy_number: string,
  ): Promise<PortfolioClient> {
    let res;

    if (type === "bpk") {
      res = await axios.get(`/policy/${policy_number}/general`);
    } else if (type === "wc") {
      res = await axios.get(`/policy/${policy_number}/wc`);
    }

    const { data } = res;

    if (data.selfAssessmentScore) {
      const selfAssessmentScore: any = {};
      selfAssessmentScore.scores = formatScores(data.selfAssessmentScore);
      selfAssessmentScore.factors = formatFactors(data.selfAssessmentScore);
      selfAssessmentScore.version =
        data.score?.score_factors || data.selfAssessmentScore?.score_factors
          ? 2
          : 1;
      const factorTotal = selfAssessmentScore.factors.reduce(
        (partialSum, factor) => partialSum + (factor.current || 0),
        0,
      );
      if (selfAssessmentScore.scores.current !== factorTotal) {
        selfAssessmentScore.scores.current = factorTotal;
      }
      data.selfAssessmentScore = selfAssessmentScore;
    }
    // use Industry data if available but no score info
    if (!data.selfAssessmentScore && data.industry?.industryStat) {
      const selfAssessmentScore: any = {};
      selfAssessmentScore.scores = formatScores(data.industry.industryStat);
      selfAssessmentScore.version =
        data.score?.score_factors || data.selfAssessmentScore.score_factors
          ? 2
          : 1;
      data.selfAssessmentScore = selfAssessmentScore;
    }
    if (
      data.iAuditorScore &&
      data.iAuditorScore !== "not_connected" &&
      data.iAuditorScore !== "insufficient_data"
    ) {
      const iAuditorScore: any = safetycultureService.formatIAuditorInfo(
        data.iAuditorScore,
      );
      data.iAuditorScore = iAuditorScore;
      delete data.iAuditor;
    }
    return data as PortfolioClient;
  },

  getPolicyLink(policy_id: string, policy_number: string): string {
    const baseUrl = process.env.REACT_APP_MITTI_BROKER_LOGIN_LINK;
    let type = link_ids[policy_number.includes("CPX") ? "CPX" : "BPK"];

    const policyUrl = `${baseUrl}/contract/view?pid=${type.pid}&subject=${type.subject}&topic=${policy_id}/${policy_number}`;

    return policyUrl;
  },
};

export default organisationService;
export interface PortfolioClient {
  policy: EvariPolicy;
}

export const link_ids = {
  CPX: {
    pid: "0a150d2b-0e5a-4e1b-8906-57b048374513",
    subject: "$CONTRACT/CONTRACT_BPKPLUS_POLICY",
  },
  BPK: {
    pid: "a13785be-1b43-11ec-9621-0242ac130002",
    subject: "$CONTRACT/CONTRACT_MITTI_BPK_POLICY",
  },
};
