import { AuthContext, LocationContext } from "../../../contexts";
import { CircularProgress } from "../../../components/util";
import { ClaimContactType, claimSchema } from "./claimSchema";
import { Flow } from "../../../components/content";
import { FlowSteppedFormSchema, JfsFormData } from "../../../types";
import { PageNotFound } from "../..";
import { useContext, useEffect, useState } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { useHistory } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import claimService from "../../../services/claim/claim.service";
import toastEvent from "../../../components/util/toast/toast.event";
import { CustomErrorMsg } from "../../../components/util/jfsForm/jfsForm";
import PolicyService from "../../../services/policy/policy.service";
import { unleashFeatureFlags } from "../../../constants";

const CreateClaim = () => {
  const { urlParams } = useContext(LocationContext);
  const history = useHistory<any>();
  const queryClient = useQueryClient();
  let { user } = useContext(AuthContext);
  const createinspectionFlag = useFlag(
    unleashFeatureFlags.createClaimInspection,
  );

  let [schemaData, setSchemaData] =
    useState<FlowSteppedFormSchema>(claimSchema);

  let [prefillData, setPrefillData] = useState<Record<any, any> | undefined>();

  const isLinkInspectionEnabled =
    (createinspectionFlag && user?.roles.includes("admin")) ||
    user?.roles.includes("super_admin");
  // load data if policy_number passed
  const {
    isLoading: isLoadingPolicy,
    isError: isErrorPolicy,
    data: policy,
  } = useQuery<any | undefined>(
    [`claims-create-client-${urlParams?.p || ""}`, urlParams?.p],
    async () => PolicyService.get_policy_by_id(urlParams?.p || ""),
    {
      enabled: !!urlParams?.p,
    },
  );

  // Set initial user data and disabled states, reset if params change
  useEffect(() => {
    if (!prefillData || !urlParams?.p) {
      setPrefillData({
        create: {
          claim: {
            contact_name: `${user?.firstname} ${user?.lastname}`,
            phone_number: user?.phone,
            email: user?.email,
          },
        },
      });

      setSchemaData({
        ...schemaData,
        create: {
          ...schemaData.create,
          uiSchema: {
            ...schemaData.create.uiSchema,
            claimFor: {
              ...schemaData.create?.uiSchema?.claimFor,
              insured_name: {
                "ui:disabled": policy,
              },
              policy_number: {
                "ui:disabled": policy,
              },
              contact_name: {
                "ui:disabled": !!user?.firstname,
              },
              phone_number: {
                "ui:disabled": !!user?.phone,
              },
              email: {
                "ui:disabled": !!user?.email,
              },
            },
          },
        },
      });
    }

    if (
      prefillData &&
      policy &&
      !isLoadingPolicy &&
      !(
        prefillData?.create.claim.insured_name ||
        prefillData?.create.claim.policy_number
      )
    ) {
      const insuredName: string =
        policy.policy_data.customer_insured_name ||
        policy.policy_data.BUSINESS_NAME;

      const polNum: string =
        policy.policy_data?.policy_number || policy.policy_data?.POLICY_NUMBER;

      setPrefillData({
        create: {
          claim: {
            insured_name: insuredName,
            policy_number: polNum,
            ...prefillData?.create.claim,
          },
        },
      });

      setSchemaData({
        ...schemaData,
        create: {
          ...schemaData.create,
          uiSchema: {
            ...schemaData.create.uiSchema,
            claimFor: {
              ...schemaData.create?.uiSchema?.claim,
              insured_name: {
                "ui:disabled": !!insuredName,
              },
              policy_number: {
                "ui:disabled": !!polNum,
              },
            },
          },
        },
      });
    }
  }, [
    isLoadingPolicy,
    policy,
    prefillData,
    schemaData,
    urlParams?.p,
    user?.email,
    user?.firstname,
    user?.lastname,
    user?.phone,
  ]);

  // useEffect(() => {
  //   // enable inspection linking if user is admin or super admin
  //   const isCurrentlyEnabled =
  //     !schemaData.declarations.uiSchema?.linked_inspection_section[
  //       "ui:disabled"
  //     ];
  //   if (!isCurrentlyEnabled && isLinkInspectionEnabled) {
  //     setSchemaData({
  //       ...schemaData,
  //       declarations: {
  //         ...schemaData.declarations,
  //         uiSchema: {
  //           ...schemaData.declarations.uiSchema,
  //           linked_inspection_section: {
  //             "ui:disabled": false,
  //           },
  //         },
  //       },
  //     });
  //   }
  // }, [isLinkInspectionEnabled, schemaData]);

  const onSubmit = (submitData: JfsFormData): void => {
    const policy_id = policy.id;
    claimService.create(policy_id, user?.id || "", submitData).then(claim => {
      queryClient.removeQueries(`claims_${policy.id}`);
      queryClient.removeQueries(
        `claims-associated-${policy.broker_org_id}-${policy.id}`,
      );
      toastEvent.success(`Claim ${claim.claim_number} created!`);
      history.push({
        pathname: `/claims/${claim.id}`,
        state: { claimData: submitData },
      });
    });
  };

  const FlowOrHide = () => {
    if (
      (!prefillData?.create.claim.policy_number ||
        !prefillData?.create.claim.insured_name) &&
      (urlParams?.o || urlParams?.p)
    ) {
      if (isLoadingPolicy) {
        return <CircularProgress contained />;
      } else if (isErrorPolicy) {
        return <PageNotFound />;
      }
    }
    return (
      <Flow
        id="claim-create"
        name="New claim"
        schema={schemaData}
        customErrMsg={customErr}
        onSubmit={onSubmit}
        prefill={prefillData}
      />
    );
  };

  return (
    <>{useFlag("portal.claims.create") ? <FlowOrHide /> : <PageNotFound />}</>
  );
};

export default CreateClaim;
const customErr: CustomErrorMsg = {
  ".what.estimated_loss": [
    {
      type: "minimum",
      errorMsg: "should be greater than 0",
    },
  ],
  ".claim.phone_number": [
    {
      type: "format",
      errorMsg: "should be in AU format",
    },
  ],
};
