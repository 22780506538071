import { Grid } from "../../../components/util";
import { Header, Section } from "../../../components/layout";
import ClaimsList from "./ClaimsList";

const Claims = () => {
  return (
    <Grid>
      <Header
        title="Claims"
        type="article"
        titleIcon="shield"
        titleIconPosition="start"
      />
      <Section>
        <ClaimsList />
      </Section>
    </Grid>
  );
};
export default Claims;
