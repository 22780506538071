import { useQuery } from "react-query";
import { useState } from "react";
import { Header, Section } from "../../../layout";
import {
  Button,
  CircularProgress,
  TableCell,
  TableRow,
  Link,
} from "../../../util";
import { PaginatedResults } from "../..";
import toolkitService, {
  Toolkit,
} from "../../../../services/toolkits/toolkits.service";
import * as Styled from "./styles.css";
import { AddToolkitToOrgModal } from "../../../../pages/organisations/details/ToolkitsTab/AddToolkitToOrgModal";

const ToolkitRow = (toolkits: Toolkit[]) => {
  return (
    <>
      {toolkits.map((toolkit: Toolkit, i: number) => (
        <TableRow key={i}>
          <TableCell>
            {<Link href={`/toolkits/${toolkit.id}`}>{toolkit.name}</Link>}
          </TableCell>
          <TableCell>{toolkit.code}</TableCell>
        </TableRow>
      ))}
    </>
  );
};

const Toolkits = ({ orgId }: { orgId: string }) => {
  const { data, isLoading, refetch } = useQuery(
    `toolkits_${orgId}`,
    async () => {
      return toolkitService.getToolkits({ org_id: orgId });
    },
  );

  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <Section>
        <Header
          title="Toolkits"
          type="section"
          actions={
            <Button onClick={() => setModalVisible(true)}>
              Add a toolkit
              <Styled.PlusIcon icon="plus" size="sm" />
            </Button>
          }
        ></Header>
        {!isLoading && (
          <PaginatedResults
            id={`toolkits-org_id-${orgId}`}
            inputResults={{
              hits: data ? data : [],
              total: data ? data.length : 0,
            }}
            tableColumns={[
              { title: "Name", expand: true },
              { title: "Code", expand: true },
            ]}
            tableRows={ToolkitRow}
            missing={{
              icon: "pencil",
              message: "No toolkits associated to organisation",
              type: "toolkits",
            }}
          />
        )}
        {isLoading && <CircularProgress contained />}
      </Section>
      {modalVisible && (
        <AddToolkitToOrgModal
          orgId={orgId}
          onCancel={() => setModalVisible(false)}
          onToolkitAdded={async () => {
            refetch();
            setModalVisible(false);
          }}
        />
      )}
    </>
  );
};

export default Toolkits;
