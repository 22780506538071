import React, { useMemo, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { PolicyHolderScInvite } from "./PolicyHolderScInvite.fields";
import {
  Button,
  Typography,
  Link,
  InlineBanner,
} from "@safetyculture/sc-web-ui";
import { InviterInformation } from "./InviterInformation";
import { SelectWithValidation } from "../../../components/form/SelectWithValidation/SelectWithValidation";
import { InputWithValidation } from "../../../components/form/InputWithValidation";
import { InvitationPrefill } from "../../../services/invitation/invitation.service";
import { FormRow, InviteFormGrid } from "../styles.css";
import { validation_rules } from "../../../components/form/validation_rules";
import { useToolkitSelectOptions } from "../../../hooks/queries/select_options/useToolkitSelectOptions.query";
import { Spacing } from "../../../components/layout/Spacing";
import { CircleExclamation } from "@safetyculture/icons-react";

type Props = {
  invite_prefill?: InvitationPrefill | null;
  onSubmit: (fields: PolicyHolderScInvite) => void;
  policy_id: string | null;
  is_privileged_user: boolean;
  is_policy_invitate_to_sc_disabled: boolean;
};

export const PolicyInviteForm: React.FC<Props> = ({
  onSubmit,
  invite_prefill,
  policy_id,
  is_privileged_user,
  is_policy_invitate_to_sc_disabled,
}) => {
  const [toolkit_search_term, setToolkitSearchTerm] = useState("");

  const form = useForm<PolicyHolderScInvite>({
    defaultValues: useMemo(
      () => ({
        firstname: invite_prefill?.fields.firstname || "",
        lastname: invite_prefill?.fields.lastname || "",
        email: invite_prefill?.fields.email || "",
        toolkit_code: invite_prefill?.fields?.toolkit?.code
          ? {
              value: invite_prefill?.fields.toolkit.id,
              label: invite_prefill?.fields.toolkit.code,
            }
          : undefined,
        sender_first_name: invite_prefill?.fields.sender_first_name || "",
        sender_last_name: invite_prefill?.fields.sender_last_name || "",
        email_cc:
          invite_prefill?.fields.email_cc
            ?.filter(e => e !== null)
            .map((e: string) => ({
              value: e,
              label: e,
            })) || [],
        email_bcc:
          invite_prefill?.fields.email_bcc
            ?.filter(e => e !== null)
            .map((e: string) => ({
              value: e,
              label: e,
            })) || [],
      }),
      [invite_prefill],
    ),
    mode: "all",
  });

  const toolkit_options = useToolkitSelectOptions({
    policy_id: policy_id || undefined,
    toolkit_search_term,
  });

  const is_submit_disabled = useMemo<boolean | undefined>(() => {
    if (is_policy_invitate_to_sc_disabled) {
      return true;
    }
    if (!invite_prefill?.meta?.sender_org_name) {
      return true;
    }
  }, [invite_prefill, is_policy_invitate_to_sc_disabled]);

  return (
    <FormProvider {...form}>
      <Typography variant="titleLarge">Policy Holder</Typography>
      <InviteFormGrid>
        <FormRow>
          <div className="column">
            <InputWithValidation
              name="firstname"
              rules={{
                required: { value: true, message: "First name is required" },
              }}
              label="First name"
              placeholder="First name"
              control={form.control}
            />
          </div>
          <div className="column">
            <InputWithValidation
              name="lastname"
              rules={{
                required: { value: true, message: "Last name is required" },
              }}
              label="Last name"
              placeholder="Last name"
              control={form.control}
            />
          </div>
        </FormRow>
        <FormRow>
          <InputWithValidation
            name="email"
            rules={{
              required: { value: true, message: "Email address is required" },
              pattern: {
                value: validation_rules.patterns.email,
                message: "Invalid email address",
              },
            }}
            label="Email"
            placeholder="Email address"
            control={form.control}
          />
        </FormRow>
        <FormRow $flexDirection="column">
          {
            <SelectWithValidation
              control={form.control}
              multiple={false}
              label="Toolkit"
              name="toolkit_code"
              data={toolkit_options.data}
              isLoading={toolkit_options.isLoading}
              searchable={setToolkitSearchTerm}
              margin={{ top: 24 }}
              filterFn={() => true} // disable static filtering
            />
          }
          {!toolkit_options.isLoading && (
            <>
              {is_privileged_user &&
                invite_prefill?.meta?.invalid_toolkit?.code && (
                  <>
                    <Spacing $height={8} />
                    <InlineBanner status="warning" icon={<CircleExclamation />}>
                      Toolkit code "
                      {invite_prefill?.meta?.invalid_toolkit?.code}" listed on
                      policy doesn't link to an existing toolkit and can't be
                      selected.
                    </InlineBanner>
                  </>
                )}
              <Spacing $height={24} />
            </>
          )}
        </FormRow>
      </InviteFormGrid>
      <InviterInformation
        control={form.control}
        is_privileged_user={is_privileged_user}
        invite_prefill={invite_prefill}
        policy_id={policy_id}
      />
      <InviteFormGrid>
        <Button
          type="button"
          onClick={form.handleSubmit(onSubmit)}
          style={{ marginTop: 32 }}
          variant="primary"
          disabled={is_submit_disabled}
          isLoading={form.formState.isSubmitting}
        >
          Invite
        </Button>
        <Spacing $height={24} />
        {!invite_prefill?.meta?.sender_org_name && (
          <Typography variant="bodyMedium" color="negative.text.default">
            Please ensure this policy is attached to an organisation before
            initiating an invitation.
          </Typography>
        )}

        <Spacing $height={24} />

        {is_policy_invitate_to_sc_disabled && (
          <Typography variant="bodyMedium" color="negative.text.default">
            Invites to SafetyCulture have been disabled for this policy. To
            re-enable invitations,{" "}
            <Link to={`/policies/${policy_id}`}>go to the policy page</Link> and
            click "Settings".
          </Typography>
        )}
      </InviteFormGrid>
    </FormProvider>
  );
};
