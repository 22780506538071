import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext, LocationContext } from "../../../../contexts";
import authService, {
  SignUpArgs,
} from "../../../../services/auth/auth.service";
import invitationService from "../../../../services/invitation/invitation.service";
import { Form, Link, Typography } from "../../../../components/util";
import AuthContainer from "../../authContainer";
import PersonalInfo, { PersonalData } from "./personalInfo";

const SignUp = () => {
  const auth = useContext(AuthContext);
  const history = useHistory();

  if (auth.user) {
    history.push("/already-logged-in");
  }

  const { location, urlParams } = useContext(LocationContext);

  // TODO: refactor into automatic param reading by form based on field ID
  const formPrefill = (
    urlParams: Record<string, any> | undefined,
    formData?: Record<string, any>[],
    formErrors?: Record<string, boolean>[],
  ): Record<string, any>[] | Record<string, boolean>[] | undefined => {
    if (formData && authService.isLoggedIn()) {
      formData[0].isExistingAuth0User = true;
    }
    if (formData && urlParams?.email) {
      formData[0].email = urlParams.email;
    }
    if (formErrors && urlParams?.email) {
      formErrors[0].email = false;
    }
    if (formData && urlParams?.first_name) {
      formData[0].firstName = urlParams.first_name;
    }
    if (formErrors && urlParams?.first_name) {
      formErrors[0].firstName = false;
    }
    if (formData && urlParams?.last_name) {
      formData[0].lastName = urlParams.last_name;
    }
    if (formErrors && urlParams?.last_name) {
      formErrors[0].lastName = false;
    }

    return formErrors ? formErrors : formData;
  };

  if (urlParams?.invite === "true") {
    (async () => {
      const inviteStatus = await invitationService.getInvitationStatus(
        urlParams.invId as string,
      );
      if (inviteStatus === "cancelled" || inviteStatus === "claimed") {
        history.push(`/auth/invitation-${inviteStatus}`);
      } else {
        try {
          const scim = await authService.getScimUserDetails();

          if (urlParams?.email === scim.userName) {
            const queryParams = new URLSearchParams(urlParams).toString();
            history.push(
              `/link-sc-user-to-care?${queryParams}&isExistingAuth0User=true`,
            );
          } else if (urlParams?.invId && urlParams?.email !== scim.userName) {
            history.push(`/auth/invitation-email`);
          }
        } catch {}
      }
    })();
  }

  useEffect(() => {}, [urlParams?.email, history]);

  const signUpService = async (data: PersonalData) => {
    try {
      const signUpData: SignUpArgs = {
        ...data,
        industry: "",
        phoneNumber: "",
        isBroker: urlParams?.role === "broker" ? true : false,
        companyName:
          urlParams?.orgName ||
          data.firstName + " " + data.lastName + " Organisation",
        organisationSize: "",
        invitation_id: urlParams?.invId || undefined,
      };

      await authService.signUp(signUpData);
    } catch (error) {
      console.error(error);
    }
  };

  let validationErrors: any[] = [personalErrorsInit];
  let validationFields: any[] = [personalDataInit];

  return (
    <AuthContainer isSidebarVisible backLink={false} preventRedirect>
      <Form
        onSubmit={{
          service: signUpService,
          onSuccess: () => history.push(`/login`),
        }}
        validationErrors={validationErrors}
        validationFields={validationFields}
        prefill={formPrefill}
        submitText={"Create account"}
      >
        <PersonalInfo
          footer={
            <Typography variant="body2" styled={{ text: { align: "center" } }}>
              Already have an account?{" "}
              <Link href="/login" router>
                Sign in
              </Link>
            </Typography>
          }
          submitText="Create account"
          subtitle="Get access to SafetyCulture Care and start mitigating risk. Be a step ahead with a free SafetyCulture account."
          title="Get started"
        />
      </Form>
    </AuthContainer>
  );
};

const personalDataInit: Record<string, string | boolean> = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  isExistingAuth0User: false,
  has_consent: false,
};

const personalErrorsInit: Record<string, boolean> = {
  firstName: true,
  lastName: true,
  email: true,
  password: true,
  has_consent: false,
};

export default SignUp;
