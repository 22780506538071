import axios from "../../axios";
import { Invitation } from "../invitation/invitation.service";
import { IUser } from "../admin/admin.service";
import toastEvent from "../../components/util/toast/toast.event";
import { OrgInfo } from "../../pages/users/details/UserOrgModal";
import {
  OrderType,
  SortType,
  PaginatedApiResponse,
} from "../../types/pagination.type";

export enum PolicyStatus {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  VOIDED = "VOIDED",
  CANCELLED = "CANCELLED",
  DRAFT = "DRAFT",
}

export interface EvariPolicy {
  policy_id: string;
  policy_product_id: string;
  policy_number: string;
  policy_created_date: string;
  policy_expiry_date: string;
  policy_updated_date: string;
  policy_inception_date: string;
  policy_status: PolicyStatus;
  agent_id: string;
  agent_email: string;
  agent_name: string;
  agent_role: string;
  team_id: string;
  customer_id: string;
  customer_insured_name: string;
  customer_first_name: string;
  customer_last_name: string;
  customer_email: string;
  customer_organisation_id?: string;
  anzsic_code: string;
  invitations?: Invitation[];
  is_registered?: boolean;
  product_type: string;
}

const userService = {
  async addUserOrg(data: OrgInfo): Promise<boolean> {
    try {
      const user = await axios.post("/users/add/org", data);
      toastEvent.success("User organisation added.");
      return user.data;
    } catch (err) {
      toastEvent.error("Error: failed to add organisation.");
      throw err;
    }
  },

  async updateUserOrg(data: OrgInfo): Promise<boolean> {
    try {
      const user = await axios.patch("/users/update/org", data);
      toastEvent.success("User organisation updated.");
      return user.data;
    } catch (err) {
      toastEvent.error("Error: failed to update user's organisation.");
      throw err;
    }
  },

  async removeUserOrg(data: OrgInfo): Promise<boolean> {
    try {
      const user = await axios.delete(
        `/users/remove/organisation_user/org_id/${data.org_id}/user_id/${data.user_id}`,
      );
      toastEvent.success("User organisation removed.");
      return user.data;
    } catch (err) {
      toastEvent.error("Error: failed to remove user's organisation.");
      throw err;
    }
  },

  async getUserByID(id: String): Promise<IUser> {
    // eslint-disable-next-line no-useless-catch
    try {
      const user = await axios.get(`/users/${id}`);
      return user.data;
    } catch (err) {
      throw err;
    }
  },

  async getAllUsers(
    offset: number,
    limit: number,
  ): Promise<PaginatedApiResponse<IUser>> {
    const sort_type: SortType = "created_at";
    const order_by: OrderType = "desc";

    let params = new URLSearchParams({
      order_by,
      sort_type,
      limit: `${limit}`,
      page_number: `${offset}`,
    });

    const user = await axios.get("/users/all", { params });

    return user.data as PaginatedApiResponse<IUser>;
  },

  async getAllUsersBySearchTerm(
    searchTerm: string,
    offset: number,
    limit: number,
  ): Promise<PaginatedApiResponse<IUser>> {
    const sort_type: SortType = "created_at";
    const order_by: OrderType = "desc";
    let params = new URLSearchParams({
      searchTerm: searchTerm.trim(),
      order_by,
      sort_type,
      limit: `${limit}`,
      page_number: `${offset}`,
    });

    const user = await axios.get("/users/all", {
      params,
    });

    return user.data as PaginatedApiResponse<IUser>;
  },
};

export default userService;
