import { useQuery, UseQueryResult } from "react-query";
import InvitationService, {
  EmailCcPrefill,
} from "../../../services/invitation/invitation.service";
import { SelectOption } from "../../../components/form/SelectWithValidation/SelectWithValidation";

export const useContactEmailSelectOptions = (options: {
  org_id: string;
  policy_id?: string;
  additional_options?: SelectOption<EmailCcPrefill>[];
}): UseQueryResult<SelectOption<EmailCcPrefill>[]> => {
  const fetchEmailOptions = async (): Promise<
    SelectOption<EmailCcPrefill>[] | undefined
  > => {
    if (options.org_id) {
      let emailsCc: EmailCcPrefill[] | undefined;
      if (options.policy_id) {
        emailsCc = await InvitationService.fetchPrefillEmailCcForPolicy({
          policyId: options.policy_id,
          orgId: options.org_id,
        });
      } else {
        emailsCc = await InvitationService.fetchPrefillEmailCcForOrg(
          options.org_id,
        );
      }
      const email_options: SelectOption<EmailCcPrefill>[] =
        emailsCc?.map((e: EmailCcPrefill) => ({
          value: e.email,
          label: e.email,
          meta: e,
        })) || [];
      if (options.additional_options?.length) {
        options.additional_options.forEach(
          (e: SelectOption<EmailCcPrefill>) => {
            if (
              email_options.findIndex(existing => existing.value === e.value) <
              0
            ) {
              email_options.push(e);
            }
          },
        );
      }
      return email_options;
    }
    return options.additional_options?.length ? options.additional_options : [];
  };

  return useQuery({
    queryKey: [
      "contact_email_select_options",
      options.org_id,
      options.policy_id,
      options.additional_options?.length,
    ],
    queryFn: fetchEmailOptions,
  });
};
