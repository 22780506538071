import { iLabelProps } from "../../components/content/label/label";
import { TPolicyType } from "../../types";
import { Typography } from "@safetyculture/sc-web-ui";

const PolicyLabel = ({ type, short }: iPolicyLabelProps) => {
  return (
    <Typography variant="bodyMediumStrong">
      {policyProductType(type, short)}
    </Typography>
  );
};

interface iPolicyLabelProps extends Omit<iLabelProps, "color" | "size"> {
  type: TPolicyType;
  short?: boolean;
}

const policyProductType = (type: TPolicyType, short?: boolean): string => {
  switch (type) {
    case "cpx":
      return short ? "CPX" : "Commercial Package";
    case "bpk":
      return short ? "BPK" : "Business Package";
    case "wc":
      return short ? "WC" : "Worker's Compensation";
    default:
      return short ? "Unkown" : "Unknown product type";
  }
};

export default PolicyLabel;
