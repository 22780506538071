import { Select } from "@safetyculture/sc-web-ui";
import { ObjectOrUndefined } from "@safetyculture/sc-web-ui/cjs/packages/react/select/types";
import { ControllerRenderProps, FieldValues, Path } from "react-hook-form";
import {
  SelectOption,
  SelectWithValidationProps,
} from "./SelectWithValidation";

export type Props<T extends FieldValues, J extends ObjectOrUndefined> = Pick<
  SelectWithValidationProps<T, J>,
  "multiple" | "rules"
> & {
  children: React.ReactNode;
  field: ControllerRenderProps<T, Path<T>>;
  resetSearch: () => void;
};

export function SelectWrapper<
  T extends FieldValues,
  J extends ObjectOrUndefined,
>({ children, field, multiple, rules, resetSearch }: Props<T, J>) {
  if (multiple) {
    return (
      <Select
        multiple={true}
        defaultValue={field.value || undefined}
        onChange={(options?: SelectOption[]) => {
          resetSearch();
          field.onChange(options || null);
        }}
      >
        {children}
      </Select>
    );
  } else {
    return (
      <Select
        defaultValue={field.value || undefined}
        onChange={(option?: SelectOption) => {
          resetSearch();
          field.onChange(option || null);
        }}
        preventUnset={rules?.required ? true : false}
      >
        {children}
      </Select>
    );
  }
}
