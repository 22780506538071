import { CircleInfo } from "@safetyculture/icons-react";
import { InlineBanner } from "@safetyculture/sc-web-ui";
import * as React from "react";

export const AutomationInfoBanner: React.FC = () => {
  return (
    <InlineBanner icon={<CircleInfo />} status="neutral">
      Automations provide policy invitations with a default toolkit if no other
      source is found. Automations trigger by matching ANZSIC codes with a
      policy.
    </InlineBanner>
  );
};
