import * as React from "react";
import { Chip, Typography } from "../../components/util";

type Props = { enabled?: boolean };

export const AutomationStatusChip: React.FC<Props> = ({ enabled }) => {
  return (
    <Chip
      color={enabled ? "positive" : "surface"}
      size="xs"
      data-testid="org-type-chip"
    >
      <Typography styled={{ text: { case: "title" } }}>
        {enabled ? "Active" : "Inactive"}
      </Typography>
    </Chip>
  );
};
