import { useContext, useState } from "react";
import { Typography, Spinner } from "@safetyculture/sc-web-ui";
import AuthContainer from "../../authContainer";
import AuthFooter from "../../authFooter";
import { useHistory } from "react-router-dom";
import { LocationContext } from "../../../../contexts";
import authService, {
  SignUpArgs,
} from "../../../../services/auth/auth.service";
import { useAsyncEffect } from "../../../../hooks/useAsyncEffect";
import { Check } from "@safetyculture/illustrations-react";
import { CenterIllustrationContainer } from "../style.css";

const LinkSCuserToCare = () => {
  const history = useHistory();
  const { urlParams } = useContext(LocationContext);
  const does_sc_user_exist = urlParams?.userAlreadyExistsInSC || false;
  const [linkingUser, setLinkingUser] = useState<boolean | undefined>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>();

  useAsyncEffect(async () => {
    const signUpData: SignUpArgs = {
      industry: "",
      phoneNumber: "",
      isBroker: urlParams?.role === "broker" ? true : false,
      companyName: urlParams?.orgName || "",
      organisationSize: "",
      firstName: urlParams?.first_name || "",
      lastName: urlParams?.last_name || "",
      email: urlParams?.email || "",
      password: "",
      has_consent: false,
      isExistingAuth0User: does_sc_user_exist,
      invitation_id: urlParams?.invId || undefined,
    };

    try {
      if (does_sc_user_exist) {
        await authService.signUp(signUpData);
        history.push(`/`);
      }
    } catch (error: Error | unknown) {
      setError(true);
      setErrorMsg((error as Error)?.message);
    }
    setLinkingUser(true);
  }, [
    does_sc_user_exist,
    history,
    urlParams?.email,
    urlParams?.first_name,
    urlParams?.invId,
    urlParams?.last_name,
    urlParams?.orgId,
    urlParams?.orgName,
    urlParams?.polNum,
    urlParams?.role,
  ]);

  return (
    <AuthContainer
      headerTitle="Linking Safetyculture user to Care"
      preventRedirect
      footerContent={<AuthFooter />}
      stickyFooter
    >
      {!linkingUser ? (
        <Spinner />
      ) : error ? (
        <Typography variant="bodyMedium">{errorMsg}</Typography>
      ) : (
        <>
          <CenterIllustrationContainer>
            <Check />
          </CenterIllustrationContainer>
          <Typography variant="bodyMedium">
            Your SafetyCulture account has been successfully linked to Care.
          </Typography>
        </>
      )}
    </AuthContainer>
  );
};

export default LinkSCuserToCare;
