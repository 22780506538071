import axios from "../../axios";
import {
  OrderType,
  PaginatedApiResponse,
  SortType,
} from "../../types/pagination.type";

export interface Industry {
  id: string;
  name: string;
  occupation: string;
  anzsic?: string;
  created_at: string;
  updated_at: string;
}

/** Industry stat and factor seem to be legacy code */
export interface IndustryStat {
  average?: number;
  best_practice?: number;
}

export interface IndustryFactor {
  industry_id: string;
  name: string;
  max: number;
  score?: string;
}

const IndustriesService = {
  async getAllIndustries(): Promise<Industry[]> {
    const result = await axios.get("/industries", {
      params: {
        limit: 50,
        page_number: 0,
        order_by: "asc",
      },
    });
    return result.data;
  },
  async searchAll(
    search_term: string,
  ): Promise<PaginatedApiResponse<Industry>> {
    const order_by: OrderType = "asc";

    const result = await axios.get("/industries", {
      params: {
        search: search_term.trim(),
        order_by,
        sort_type: undefined,
        limit: 50,
        page_number: 0,
      },
    });

    return result.data;
  },
};

export default IndustriesService;
