import { useQuery, UseQueryResult } from "react-query";
import toolkitService, {
  Toolkit,
} from "../../services/toolkits/toolkits.service";

export const useToolkitQuery = (options?: {
  org_id?: string;
  policy_id?: string;
}): UseQueryResult<Toolkit[]> => {
  const fetchToolkits = async (): Promise<Toolkit[] | undefined> => {
    let toolkits: Toolkit[] | undefined;
    if (options?.org_id || options?.policy_id) {
      toolkits = await toolkitService.getToolkits(options);
    }
    if (toolkits) {
      return toolkits.filter(t => t.code !== "");
    }
    return [];
  };

  return useQuery({
    queryKey: ["toolkit_query", options?.policy_id, options?.org_id],
    queryFn: fetchToolkits,
  });
};
