import { Grid, Typography } from "../../util";
import * as Styled from "./styles.css";

interface iSummaryProps {
  data: { [key: string]: string | React.ReactElement }[];
}

const Summary = ({ data }: iSummaryProps) => {
  return (
    <Styled.Detail container>
      {data.map((item, index: number) => {
        const value = item[Object.keys(item)[0]];

        return (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Typography
              element="p"
              paragraph
              variant="body2"
              styled={{
                text: { shade: "weaker", case: "upper", weight: "default" },
              }}
            >
              {Object.keys(item)[0].replace(/_/g, " ")}
            </Typography>
            {typeof value === "string" && (
              <Typography
                element="p"
                styled={{ text: { case: "sentence", weight: "heavy" } }}
                truncate={true}
              >
                {value.replace(/_/g, " ")}
              </Typography>
            )}
            {typeof value !== "string" && <>{value}</>}
          </Grid>
        );
      })}
    </Styled.Detail>
  );
};

export default Summary;
