import * as React from "react";
import { AuthContext } from "../contexts";
import { Redirect } from "react-router-dom";

type Props = {};

export const HomeRedirect: React.FC<Props> = () => {
  const { user } = React.useContext(AuthContext);

  // if user is unverified, redirect to get-started
  if (user?.roles?.includes("unverified")) {
    return <Redirect to="/get-started" />;
  }

  // otherwise redirect based on user role
  switch (user?.roles[0]) {
    case "admin":
    case "super_admin":
      return <Redirect to="/users" />;
    case "customer": // customers are directly redirected to SC app
      if (process.env.REACT_APP_IAUDITOR_LINK) {
        window.location.href = process.env.REACT_APP_IAUDITOR_LINK;
      }
      return null;
    case "broker":
      return <Redirect to="/portfolio" />;
  }

  return null;
};
