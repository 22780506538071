import { useEffect, useState, useContext } from "react";
import { useQuery } from "react-query";
import AuthContext from "../../../contexts/authContext";
import prismicService, {
  BenchmarksCaseStudiesInterface,
  BenchmarksCostsInterface,
  BenchmarksIssuesInterface,
  BenchmarksOverviewInterface,
} from "../../../services/prismic/prismic.service";
import { CircularProgress, Grid } from "../../../components/util";
import { ListMissing } from "../../../components/content";
import Overview from "./overview";
import Issues from "./issues";
import Costs from "./costs";
import CaseStudies from "./caseStudies";

const Benchmarks = () => {
  const auth = useContext(AuthContext);
  const [overview, setOverview] = useState<BenchmarksOverviewInterface>();
  const [costs, setCosts] = useState<BenchmarksCostsInterface | undefined>();
  const [issues, setIssues] = useState<BenchmarksIssuesInterface | undefined>();
  const [caseStudies, setCaseStudies] =
    useState<BenchmarksCaseStudiesInterface[] | undefined>();

  const { isLoading, isError, data } = useQuery(
    "InsightsBenchmarks",
    () =>
      prismicService.getBenchmarks(
        auth.user?.organisations[0].industry.name || "",
      ),
    { retry: 0 },
  );

  useEffect(() => {
    if (!isLoading && data) {
      setOverview(data.overview);
      setCosts(data.costs);
      setIssues(data.issues);
      setCaseStudies(data.caseStudies);
    }
  }, [data, isLoading]);

  if (isLoading) {
    return <CircularProgress contained />;
  } else if (isError || data == null) {
    return (
      <ListMissing
        icon="pulse"
        message="Your industy doesn't have any information at the moment, please check back later."
        type="information"
      />
    );
  }

  return (
    <Grid container spacing={3}>
      {overview && <Overview overview={overview} />}
      {issues && <Issues issues={issues} />}
      {costs && <Costs costs={costs} />}
      {caseStudies && <CaseStudies caseStudies={caseStudies} />}
    </Grid>
  );
};

export default Benchmarks;
