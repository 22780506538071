import { recordAnalyticsEvent } from "../../analytics";
import { Header, Section } from "../../components/layout";
import { useFlag } from "@unleash/proxy-client-react";
import ClientList from "../portfolio/clientList";
import * as Styled from "./styles.css";
import { Link } from "@safetyculture/sc-web-ui";

const Policies = () => {
  const handleQuoteClick = () => {
    recordAnalyticsEvent("action", "Create Quote", {
      path: process.env.REACT_APP_MITTI_BROKER_LOGIN_LINK || "",
    });
  };

  return (
    <Styled.Grid $fullHeight={true}>
      <Header
        actions={
          useFlag("portal.quotebutton") && (
            <Link
              type="button"
              href={process.env.REACT_APP_MITTI_BROKER_LOGIN_LINK}
              target="_blank"
              onClick={handleQuoteClick}
              style={{ color: "white" }} // temporary // TODO: Upgrade sc-web-ui to remove this line
            >
              Create quote
            </Link>
          )
        }
        title="Policies"
        titleIcon="file"
        titleIconPosition="start"
        type="article"
      />

      <Section>
        <ClientList />
      </Section>
    </Styled.Grid>
  );
};

export default Policies;
