import { useContext, useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { RecordMissing } from "../../../components/content";
import { CircularProgress } from "../../../components/util";
import * as Styled from "./styles.css";
import Bpk from "./bpk";
import Cpx from "./cpx";
import { AuthContext, GuidanceContext } from "../../../contexts";

import useLocalStorageState from "use-local-storage-state";
import { usePolicyQuery } from "../../../hooks/queries/usePolicyQuery";
import { formatDateTime } from "../../../helpers";

const PolicyDetails = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const { policy_id } = useParams<IPolicyPageParams>();
  const policy_query = usePolicyQuery(policy_id);
  const search_query = queryString.parse(location.search);
  const backText = search_query?.backText as string;
  const { setNewBrokerClientProgress } = useContext(GuidanceContext);

  const [BCVisited, setBCVisited] = useLocalStorageState(
    `${user?.id}-BCVisited`,
    true,
  );

  useEffect(() => {
    setBCVisited(true);
    setNewBrokerClientProgress(false);
  }, [BCVisited, setBCVisited, setNewBrokerClientProgress]);

  /** Policies always have a fixed time so there can't be a gap (eg. in australia it's 4pm) */
  const formatted_created_at = useMemo(() => {
    return formatDateTime(
      policy_query.data?.created_ts || policy_query.data?.created_ts || "",
    );
  }, [policy_query.data?.created_ts]);

  const formatted_inception_date = useMemo(() => {
    return formatDateTime(policy_query.data?.effective_from_ts || "");
  }, [policy_query.data?.effective_from_ts]);

  const formatted_expiry_date = useMemo(() => {
    const policy_expiry = policy_query.data?.effective_to_ts;
    const number_days = Math.round(
      (new Date(policy_query.data?.effective_to_ts || "").getTime() -
        Date.now()) /
        (1000 * 60 * 60 * 24),
    );
    return (
      formatDateTime(policy_expiry || "") +
      (number_days > 0 ? ` (${number_days} days from now)` : "")
    );
  }, [policy_query.data?.effective_to_ts]);

  if (policy_query.isLoading) {
    return <CircularProgress contained />;
  } else if (policy_query.isError || policy_query.data == null) {
    return (
      <RecordMissing
        icon="file"
        message="Please check the policy number or navigate back to your policies."
        type="policy"
        back={{
          title: "Back to Policies",
          path: "/policies",
        }}
      />
    );
  } else
    return (
      <Styled.Grid>
        {policy_query.data?.type === "bpk" ||
        policy_query.data?.type === "wc" ? (
          <Bpk
            policy={policy_query.data}
            createdAt={formatted_created_at}
            inceptionDate={formatted_inception_date}
            expiryDate={formatted_expiry_date}
          />
        ) : (
          <></>
        )}
        {policy_query.data?.type === "cpx" ? (
          <Cpx
            policy={policy_query.data}
            createdAt={formatted_created_at}
            inceptionDate={formatted_inception_date}
            expiryDate={formatted_expiry_date}
          />
        ) : (
          <></>
        )}
      </Styled.Grid>
    );
};

interface IPolicyPageParams {
  policy_id: string;
}

export default PolicyDetails;
