import { useQuery, UseQueryResult } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../../contexts";
import toolkitService, {
  Toolkit,
} from "../../../services/toolkits/toolkits.service";
import { hasAllowedRoles } from "../../../helpers";
import { SelectOption } from "../../../components/form/SelectWithValidation/SelectWithValidation";

export const useToolkitSelectOptions = (options?: {
  toolkit_search_term?: string;
  org_id?: string;
  policy_id?: string;
}): UseQueryResult<SelectOption<Toolkit>[]> => {
  const { user } = useContext(AuthContext);
  const is_privileged_user = hasAllowedRoles(user?.roles, {
    allowed: ["admin", "super_admin"],
  });

  const fetchToolkits = async (): Promise<
    SelectOption<Toolkit>[] | undefined
  > => {
    let toolkits: Toolkit[] | undefined;
    if (is_privileged_user) {
      toolkits = (
        await toolkitService.getAll(options?.toolkit_search_term || undefined)
      ).hits;
    } else if (options?.org_id || options?.policy_id) {
      toolkits = await toolkitService.getToolkits(options);
    }
    if (toolkits) {
      return toolkits
        .map(t => ({
          label: t.code || "",
          value: t.id || "",
          meta: t,
          description: `${t.name}`,
        }))
        .filter(t => t.value !== "");
    }
    return [];
  };

  return useQuery({
    queryKey: [
      "toolkit_select_options",
      options?.toolkit_search_term,
      options?.policy_id,
      options?.org_id,
    ],
    queryFn: fetchToolkits,
  });
};
