import { useQuery, UseQueryResult } from "react-query";

import { IClaim } from "../../types";
import claimService from "../../services/claim/claim.service";

export const useClaimQuery = (
  claim_id: string,
): UseQueryResult<IClaim | undefined> => {
  const fetcher = async () => {
    return claimService.get_by_claim_id(claim_id);
  };

  return useQuery<IClaim | undefined>(
    ["use-single-claim-query", claim_id],
    fetcher,
  );
};
