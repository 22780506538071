import { v4 as uuidv4 } from "uuid";
import axios from "../axios";
import authService, { iLoginToken, User } from "../services/auth/auth.service";

const session_uuid = uuidv4();

// Segment methods for Identification and generic Event
export const recordAnalyticsEvent = async (
  eventType: eventTypes,
  eventName: eventNames,
  eventDetails: Record<string, any> | User,
) => {
  if (process.env.NODE_ENV !== "development") {
    // Do not track in local dev environment
    let user_session: Pick<iLoginToken, "access_token">;
    try {
      user_session = authService.getCurrentSession();
    } catch (err) {
      user_session = { access_token: "" };
    }

    if (eventType === "identify") {
      axios.post("/analytics/identify", {
        traits: {
          email: eventDetails.email,
          name: `${eventDetails.firstname} ${eventDetails.lastname}`,
          type: eventDetails.type,
        },
      });
    }
    if (eventType === "action") {
      axios.post("/analytics/track", {
        anonymousId: user_session.access_token ? undefined : session_uuid,
        event: eventName,
        properties: eventDetails,
      });
    }
  }
};

type eventTypes = "action" | "identify";

type eventNames =
  | "Create Quote"
  | "Form Continue Click"
  | "Logged In"
  | "Inspection"
  | "Portfolio Client View"
  | "View Policy"
  | "iAuditor"
  | "Portfolio Invite Client"
  | "Invite Client Resent"
  | "Invite Client Cancelled"
  | "Nav Menu Click"
  | "Self Assessment Started"
  | "Self Assessment Completed"
  | "Signed Up"
  | "Tab Click";
